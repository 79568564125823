import React from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn } from "@fortawesome/pro-regular-svg-icons/faToggleOn";
import { faToggleOff } from "@fortawesome/pro-regular-svg-icons/faToggleOff";
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { OverlayTrigger, Popover } from "react-bootstrap";

class PersonalizationSettings extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: this.props.user,
      samePageOnAccountChange: this.props.user.same_page_on_account_change,
    };
  }

  handleToggleClick = () => {
    this.updateUserSettings(!this.state.samePageOnAccountChange);
    this.setState((prevState) => ({ samePageOnAccountChange: !prevState.samePageOnAccountChange }));
  };

  updateUserSettings = (newSamePageOnAccountChange) => {
    const data = {
      user: {
        same_page_on_account_change: newSamePageOnAccountChange,
      },
    };
    axios.put(`/users/settings`, data).then((response) => {
      // Do nothing on success.
    });
  };

  render() {
    const { samePageOnAccountChange } = this.state;

    const popoverHoverFocus = (
      <Popover id="popover-trigger-hover-focus">
        For users that often have to look at the same section across multiple accounts, this setting will attempt to
        stay on the same corresponding page when changing accounts. If the same page can't be found on the new account
        (e.g. you're on an individual product page) then you will be redirected to the dashboard.
      </Popover>
    );

    return (
      <div className="w-full max-w-prose flex gap-4 items-start justify-between">
        <div className="text-lg">
          When changing accounts, stay on the same section
          <br />
          instead of redirecting to the dashboard&nbsp;
          <OverlayTrigger trigger={["click"]} placement="bottom" overlay={popoverHoverFocus}>
            <span className="cursor-pointer">
              <FontAwesomeIcon icon={faInfoCircle} className="text-sm text-text/50" />
            </span>
          </OverlayTrigger>
        </div>
        <button className="flex gap-2 items-center" onClick={this.handleToggleClick}>
          <span className="text-sm font-semibold uppercase">{samePageOnAccountChange ? "On" : "Off"}</span>
          <FontAwesomeIcon
            icon={samePageOnAccountChange ? faToggleOn : faToggleOff}
            className={`text-2xl ${samePageOnAccountChange ? "text-accent" : ""}`}
          />
        </button>
      </div>
    );
  }
}

export default PersonalizationSettings;
